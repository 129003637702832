import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  url : String;

  constructor() { }

  public setUrl(){
    this.url = window.location.origin;
      
  }

  public getUrl(){
      return this.url
  }

}
