import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service';
import { Property } from '../../firebase/property-model.module';
import { CarrouselFeaturedComponent } from '../carrousel-featured/carrousel-featured.component';

@Component({
  selector: 'app-edit-carousel',
  templateUrl: './edit-carousel.component.html',
  styleUrls: ['./edit-carousel.component.css']
})
export class EditCarouselComponent implements OnInit {

  properties;
  properties_carousel;
  image_carousel;
  title1 = 'Propierdad';
  title2 = 'Propierdad';
  title3 = 'Propierdad';
  title4 = 'Propierdad';
  btn;
  image1 = '../../assets/images/destacada1.jpg';
  image2 = '../../assets/images/destacada1.jpg';
  image3 = '../../assets/images/destacada1.jpg';
  image4 = '../../assets/images/destacada1.jpg';

  constructor(private service: ServiceService) {
  }

  ngOnInit(): void {
    $("#list").css('display','none');
    this.service.properties.subscribe(data=>{
      this.properties = data; 
    })
    this.getPropertiesCarousel();
  }

  getPropertiesCarousel(){
    this.service.propertiesCarousel.subscribe(data=>{
      this.properties_carousel = data;
    })
  }

  changePropertyCarousel(property,images){
    $('.list').css('display','none');
    $(".images").css('display','none');
    $(".property").css('background-color','white')
    $(".link").css('color','black')
    $("#property"+images).css('background-color',' #536114')
    $("#link"+images).css('color','white')
    $("#election"+images).css('color','white')
    switch (this.btn) {
      case 1:
        this.title1 = property.title;
        break;
      case 2:
        this.title2 = property.title;
        break;
      case 3:
        this.title3 = property.title;
        break;
      case 4:
        this.title4 = property.title;
        break;
      }
    $("#img"+images).css('display','block');
  }

  changeImg(key,i,btn){
    $(".btnChangeImg").css('display','none');
    $(".ok").css('display','none');
    $(".cancel").css('display','none');
    $("#ok"+btn).css('display','block');
    $("#cancel"+btn).css('display','block');
    var img = 'https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/'+key+'%2F'+i+'?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518';
    switch (this.btn) {
      case 1:
        this.image1 = img;
        break;
      case 2:
        this.image2 = img;
        break;
      case 3:
        this.image3 = img;
        break;
      case 4:
        this.image4 = img;
        break;
      }
    this.image_carousel = img;
  }

  elegir(btn){
    $('.button-all').css('border','none');
    $('#button-'+btn).css('border','5px solid #87a310');
    this.btn = btn;
  }

  cancelEdit(){
    $('.list').css('display','block');
    $("#list").css('display','none');
    $(".link").css('color','black');
    $(".property").css('background-color','white')
    this.title1 = 'Propierdad';
    this.title2 = 'Propierdad';
    this.title3 = 'Propierdad';
    this.title4 = 'Propierdad';
    this.image1 = '../../assets/images/destacada1.jpg';
    this.image2 = '../../assets/images/destacada1.jpg';
    this.image3 = '../../assets/images/destacada1.jpg';
    this.image4 = '../../assets/images/destacada1.jpg';
  }

  saveElection(property){
    var data_carousel = {
      'id' : this.btn,
      'key' : property.key,
      'titulo' : property.title,
      'descripcion' :'skvsiv wrigj i ji igiiergeiige irgjeii egjei',
      'imagen' : this.image_carousel,
    }

    this.service.updateCarousel(this.btn,data_carousel);

    $(".images").css('display','none');
    $(".ok").css('display','none');
    $(".cancel").css('display','none');
    $("#list").css('display','block');
    $(".btnChangeImg").css('display','block');
  }

  cancelElection(){
    $('.list').css('display','block');
    $(".images").css('display','none');
    $(".ok").css('display','none');
    $(".cancel").css('display','none');
    $("#list").css('display','block');
    $(".btnChangeImg").css('display','block');
  }
}
