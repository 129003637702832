import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { ServiceService } from '../../firebase/service.service'

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {
 
  showForm = "editProperty"
  pass = false

  constructor(private service: ServiceService) {
  }
 
  ngOnInit(): void {

    this.changeForm(this.showForm);
  }

  changeForm(option){
    $(".form").css("display","none");
    if(option == "editProperty"){
      $("#editProperty").css("display","block");
    }else if(option == "uploadProperty"){
      $("#uploadProperty").css("display","block");
    }else if(option == "uploadFiles"){
      $("#uploadFiles").css("display","block");
    }else if(option == "uploadLote"){
      $("#uploadLote").css("display","block");
    }
  }


  public login(){
    this.service.getPass().subscribe(pass=>{
      console.log(pass)
      if($("#pass").val() == pass['pass']){
        $("#login").css('display','none')
        $("#show-panel").css('display','block')
      }
    })
    
  }
}
