import { Component, } from '@angular/core';
import { ServiceService } from '../firebase/service.service'
import { Router, NavigationEnd} from '@angular/router'
import * as $ from 'jquery';

import { DataService } from './shared/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  url;
  constructor(private service: ServiceService,private router: Router,private data: DataService) {
    this.service.getPropertiesAll();
    this.service.getPropertiesCarousel();
    this.data.setUrl()
  }

  ngOnInit () {
    this.url=this.data.getUrl()
    if(String(window.location.href) !== (this.url+'/')){
      $('#about-us').css('display', 'none')
      $('#about-us-mbl').css('display', 'none')
    }else{
      $('#about-us').css('display', 'block')
      $('#about-us-mbl').css('display', 'block')
    }

    this.url = this.data.getUrl()    
    this.router.events.subscribe((event) => {
      this.modifyMenu(event)
    })
 }

 modifyMenu(event){
  if (event instanceof NavigationEnd) {
    let url = event.url;
    if(url == '/'){
      this.pageHome();
    }else{
      this.othersPage()
    }
  }
   
 }

 pageHome(){
  $('.menu').css('position','absolute')
  $('.menu').css('top','0')
  $('.menu').css('z-index','3')
  $('.menu').css('background-color','transparent')
  $('.link-menu').css('color','white')
  $(window).scroll(function() {
    if ($(window).scrollTop() > 100) {
      $('.menu').css('position','fixed')
      $('.menu').css('background-color','#fbe307')
      $('.link-menu').css('color','rgba(255, 255, 255, 0.945)')
    } else {
      $('.menu').css('background-color','transparent')
      $('.menu').css('position','relative')
      $('.link-menu').css('color','white')
      $('.menu').css('position','absolute')
      $('.menu').css('top','0')
      $('.menu').css('z-index','3')
    }
  });
 }
 
 othersPage(){
  $('.link-menu').css('color','rgba(255, 255, 255, 0.945)')
  $('.menu').css('background-color','#fbe307 ')
  $(window).scroll(function() {
    if ($(window).scrollTop() > 100) {
      $('.menu').css('background-color','#fbe307 ')
      $('.link-menu').css('color','rgba(255, 255, 255, 0.945)')
      $('.menu').css('position','fixed')
      $('.menu').css('top','0')
      $('.menu').css('z-index','3')
    } else {
      $('.menu').css('background-color','#fbe307')
      $('.link-menu').css('color','rgba(255, 255, 255, 0.945)')
      $('.menu').css('position','relative')
    }
  });
 }

footer(){
  if(screen.width  <= 768){
    $(window).scrollTop(4000);
    console.log('hola')
  }else{
    $(window).scrollTop(2300);
    console.log('hola')
  
  }
}

aboutUs(){

  if(screen.width  <= 768){
    $(window).scrollTop(2350);
    console.log('hola')
  }else{
    $(window).scrollTop(1700);
    console.log('hola')
  
  }
}
  
}
