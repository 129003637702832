import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Property } from '../firebase/property-model.module';
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { AngularFireStorage } from 'angularfire2/storage';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  properties
  propertiesCarousel
  ref : string;
  codigo : {};
  key : string;

  constructor(private db: AngularFirestore, 
              private firebase: AngularFireDatabase,
              private storage: AngularFireStorage) {
}

  public loadProperty(property: Property, images){
    this.key = this.firebase.database.ref().child('ultimoCodigo').push().key;
    property.key = this.key;
    this.db.collection('propiedades').doc(this.key).set(property);
    this.loadImages(images)
  }

  public loadLote(lote){
    this.key = this.firebase.database.ref().child('ultimoCodigo').push().key;
    lote.key = this.key;
    this.db.collection('lotes').doc(lote.key).set(lote);
  }

  public getPass(){
    return this.db.collection('pass').doc('pass').valueChanges();
  }

  public getPropertiesAll(){
     this.properties = this.db.collection('propiedades').valueChanges();
  }

  public getProperties(){
    return this.db.collection('propiedades', ref => ref.where('show', '==', true)).valueChanges();
 }

  public getProperty(id){
    return this.db.collection('propiedades').doc(id).valueChanges();
  }

  public getPropertiesSearch(){
    return this.db.collection('propiedades').doc().set({
      city : 'La Plata' });
  }

  public getLotes(){
    return this.db.collection('lotes').valueChanges();
  }

  public loadImages(images){
    let contador = 0;
    images.forEach(img => {
      let root = this.storage.storage.ref().child(this.key + '/' + contador)
      root.put(img['file'])
      contador++
    })
  }

  public loadFiles(files){
    let contador = 0;
    files.forEach(file => {
      let root = this.storage.storage.ref('documentos').child(file.name)
      root.put(file['file'])
      contador++
    })
  }

  public getFiles(){
    return this.storage.storage.ref().child('documentos')

    
  }

  public updateCarousel(key,data){
    console.log(key)
    this.db.collection('carousel').doc(key.toString()).set(data);
    
  }

  public getPropertiesCarousel(){
    this.propertiesCarousel = this.db.collection('carousel').valueChanges();
 }

 public updateProperty(key,data){
   console.log('entre')
  return this.db.collection('propiedades').doc(key.toString()).set(data);
  
 }

 public updateLote(key,data){
 return this.db.collection('lotes').doc(key.toString()).set(data);
 
}

 public resetViews(properties){
  
  properties.forEach(element => {
    element.views = 0;

    this.db.collection('propiedades').doc(element.key).set(element);
  });

 }
}
