<div id="edit-carousel" class="col-md-12">
    <div class="row pt-4" *ngIf="properties_carousel">
        <div class="col-md-3">
            <p>{{properties_carousel[0].titulo}}</p>
            <button id="button-1" class="button-all" (click)="elegir(1)">
                <img src="{{properties_carousel[0].imagen}}">
            </button>
            
        </div>
        <div class="col-md-3">
            <p>{{properties_carousel[1].titulo}}</p>
            <button id="button-2" class="button-all" (click)="elegir(2)">
                <img src="{{properties_carousel[1].imagen}}">
            </button>
        </div>
        <div class="col-md-3">
            <p>{{properties_carousel[2].titulo}}</p>
            <button id="button-3" class="button-all" (click)="elegir(3)">
                <img src="{{properties_carousel[2].imagen}}">
            </button>
        </div>
        <div class="col-md-3">
            <p>{{properties_carousel[3].titulo}}</p>
            <button id="button-4" class="button-all" (click)="elegir(4)">
                <img src="{{properties_carousel[3].imagen}}">
            </button>
        </div>
    </div>
    <div  class="row">
        
        <div class="row d-flex justify-content-around list">
            
            <div class="col-md-5 m-4 p-0 property list" *ngFor="let property of properties ; let i= index"  >
                <div class="row list " [ngClass]="'show'+property.show">
                    <div class="col-md-3 p-0 img-property">
                        <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{0}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
                    </div>
                    <div class="row col-md-9 pl-4">
                        <a [routerLink]="['/propiedad']" [queryParams]="{id: property.key}">{{property.title | slice:0:30}}...</a>
                        <div class="col-md-12">
                            <p>{{property.description | slice:0:60}}...</p>
                        </div>
                        <div class="col-md-12 d-flex justify-content-end">
                            <button class="col-md-3 m-1 btnChangeImg" (click)="changePropertyCarousel(property,i)">Cambiar</button>
                            
                        </div>  
                    </div>
                </div>
            </div>
            <div class="col-md-5 m-4 p-0 "></div>
        </div>  
        
    </div>
    <div id="list-images" class="col-md-12 pb-4">
        <div class="col-md-12 p-0 m-0 d-flex justify-content-end"><button class="col-md-2" (click)="cancelEdit()">Cancelar Edicion</button></div>
        <div [attr.id]="'property'+i" class="row property" *ngFor="let property of properties ; let i= index">
            <button [attr.id]="'ok'+i" class="col-md-1 my-1 mx-1 ok" (click)="saveElection(property)">aceptar</button>
            <button [attr.id]="'cancel'+i" class="col-md-1 my-1 mx-1 cancel" (click)="cancelElection()">cancelar</button>
            <div class="row" *ngIf="properties">
                <div class="images" [attr.id]="'img'+i">
                    <p [attr.id]="'election'+i" >Elegir Imagen</p>
                    <div class="col-md-12 d-flex justify-content-between" >
                        <button *ngFor="let j of [0,1,2,3,4,5,6,7]" class="img-{{j}} col-md-1 p-1" (click)="changeImg(property.key,j,i)"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{j}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" style="height: 100px; width: 100;" ></button>
                    </div>  
                </div>
    
            </div>
        </div>
        
    </div>
</div>
