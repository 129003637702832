import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import { ServiceService } from '../../firebase/service.service'

import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-carrousel-featured',
  templateUrl: './carrousel-featured.component.html',
  styleUrls: ['./carrousel-featured.component.css']
})
export class CarrouselFeaturedComponent implements OnInit {

  properties
  dataT = '-'
  url;

  constructor(private service: ServiceService, private data: DataService) { }

  ngOnInit(): void {
    this.url = this.data.getUrl()
    this.getPropertiesCarousel();
  }
  
  getPropertiesCarousel(){
    this.service.propertiesCarousel.subscribe(data=>{
      this.properties = data;
    })
  }

  choice(){
    var t = '-'
    var c = '-'
    var p = '-'
    var a = '-'
    if($( "#type-op option:selected" ).text() !== 'Operacion'){
      t = $( "#type-op option:selected" ).text()
    }
    if($( "#city-op option:selected" ).text() !== 'Ciudad'){
      c = $( "#city-op option:selected" ).text()
    }
    if($( "#property-op option:selected" ).text() !== 'Propiedad'){
      p = $( "#property-op option:selected" ).text()
    }
    if($( "#amb-op option:selected" ).text() !== 'Ambientes'){
      a = $( "#amb-op option:selected" ).text()
    }
  
    var url =  this.url + '/propiedades/?t=' +t+ '&c=' +c+ '&p=' +p+ '&a=' +a
    $(location).attr('href',url)
  }
}
