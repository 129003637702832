<div id="search" class="col-md-12 col-12 p-0" *ngIf="properties">
    <div id="alquiler-venta" class="col-md-12 col-12 p-0 m-0">
        <div class="row">
            <button id="search-all" class="col-md-1 col-12" (click)="changeAll()">TODAS</button>
            <button id="search-alquiler" class="col-md-2 col-6" (click)="changeAlquiler()">ALQUILER</button>
            <button id="search-venta" class="col-md-2 col-6" (click)="changeVenta()">VENTA</button>
        </div>
    </div>
    <div class="col-md-12 col-12">
        <div class="row d-flex justify-content-center">
                <div class="row ">
                    <div class="form-group col-lg-1 col-md-2 col-5 m-lg-1 m-md-3 my-2 mx-auto ">
                        <label for="exampleFormControlSelect1">Ciudad:</label>
                        <select class="form-control" id="city-op">
                        <option option="-">-</option>
                        <option option="laplata">La Plata</option>
                        <option option="mardelplata">Mar del Plata</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-1 col-md-2 col-5 m-lg-1 m-md-3 my-2 mx-auto">
                        <label for="exampleFormControlSelect1">Propiedad:</label>
                        <select class="form-control" id="property-op">
                        <option option="-">-</option>
                        <option option="casa">casa</option>
                        <option option="dpto">dpto</option>
                        <option option="duplex">duplex</option>
                        <option option="lote">lote/terrenos</option>
                        <option option="campo">campos</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-1 col-md-1 col-3 m-lg-1 m-md-3 my-2 mx-auto">
                        <label for="exampleFormControlSelect1">Ambientes:</label>
                        <select class="form-control" id="amb-op">
                        <option option="-">-</option>
                        <option option="1" type="number">1</option>
                        <option option="2" type="number">2</option>
                        <option option="3" type="number">3</option>
                        <option option="4" type="number">4</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-1 col-md-1 col-3 m-lg-1 m-md-3 my-2 mx-auto">
                        <label for="exampleFormControlSelect1">Hab.:</label>
                        <select class="form-control" id="room-op">
                            <option option="-">-</option>
                            <option option="1" type="number">1</option>
                            <option option="2" type="number">2</option>
                            <option option="3" type="number">3</option>
                            <option option="4" type="number">4</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-1 col-md-1 col-3 m-lg-1 m-md-3 my-2 mx-auto">
                        <label for="exampleFormControlSelect1">Baños:</label>
                        <select class="form-control" id="toilet-op">
                            <option option="-">-</option>
                            <option option="1" type="number">1</option>
                            <option option="2" type="number">2</option>
                            <option option="3" type="number">3</option>
                            <option option="4" type="number">4</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-2 col-md-3 col-8 m-lg-1 mx-auto">
                        <label class="col-md-3 col-3" for="exampleFormControlSelect1">Precio(USD):</label>
                        <div class="row">
                            <div class="col-lg-5 col-5 mx-lg-2 mx-auto">
                                <input id="from" class="form-control"  placeholder="$desde">
                            </div>
                            <div class="col-lg-5 col-5 mx-lg-2 mx-auto">
                                <input id="until" class="form-control"  placeholder="$hasta">
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-lg-1 col-md-1 col-3 m-lg-1 mx-auto">
                        <div class="row d-flex justify-content-center">
                            <label class="col-md-12 col-12" for="exampleFormControlSelect1">Expensas:</label>
                            <select class="form-control" id="expenses-op">
                                <option value="-">-</option>
                                <option value="si">Si</option>
                                <option value="no">No</option>
                              </select>
                            
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12 my-md-5 mx-auto d-flex justify-content-center">
                        <button id="btn-search" class="col-4 col-md-3 mx-lg-2 mx-1" (click)="searchProperties()">Buscar</button>
                        <button id="btn-clear" class="col-4 col-md-3 mx-lg-2 mx-1" (click)="clear()">Limpiar</button>
                        <button id="btn-close" class="col-4 col-md-3 mx-lg-2 mx-1" (click)="closeSearch()">Cerrar</button>
                    </div>
            </div>
            <div id="logo" class="col-md-3 d-flex justify-content-end">
            </div>
        </div>     
    </div>
</div>
<div id="filtro">
    <div class="col-12 d-flex justify-content-end">
        <button id="btn-filtro" class="col-4 " (click)="showSearch()">
            <div class="row p-2">
                <img src="../../assets/images/filtro.png" id="img-filtro">
                <p class="col-3">Filtro</p>
            </div>  
        </button>

    </div>

</div>
    <div id="latest-properties" class="col-md-12 col-12 " *ngIf="properties">
        <div cols="2" class="row justify-content-center flex-wrap p-0" >
            <div class="col-lg-3 col-md-3 col-sm-12 m-md-2 m-1 row propertie-img" *ngFor="let property of properties_pagination[btn_press]" >
                <div  *ngIf="property.show">
                    <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">   
                    <div class=" info-p col-12 px-0">
                        <a class="col-12" [routerLink]="['/propiedad']"  [queryParams]="{id: property.key}"><b>{{property.title | slice:0:20}}...</b></a>
                        <p class="my-1">
                            <b class="text-lowercase">{{property.description | slice:0:70 }}...</b>
                        </p>
                        <div class="row icon d-flex justify-content-end pt-1">
                            <img  *ngIf="screen_with>480" src="../../assets/images/icon-ambiente.png" class="img-fluid">
                            <p class="col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.ambiente}} amb.</b></p>
                            <img *ngIf="screen_with>480" src="../../assets/images/icon-room.png" class="img-fluid">
                            <p class="col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.room}} dorm.</b></p>
                            <img *ngIf="screen_with>480" src="../../assets/images/icon-toilet.png" class="img-fluid">
                            <p class="col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.toilet}} baños.</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12  my-2" style="display: block; position: relative; width: 300px;" *ngFor="let property of missing_grids">
            </div>
        </div>      
    </div>
    <div id="pag" class="col-md-12 d-flex justify-content-center">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
            <li class="page-item">
                <button class="page-link" (click)="prev()" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                </button>
            </li>
            <li  *ngFor="let i of cant_btn" id="btn-li-{{i}}" class="btn-li page-item"><button id="btn-{{i}}" class="page-link" (click)="changeProperties(i)">{{i+1}}</button></li>
            <li class="page-item">
                <button class="page-link" (click)="next()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                </button>
            </li>
            </ul>
        </nav>
    </div> 
    <div id="icon-wspp" style="position:fixed; right:10px; bottom:10px; text-align:center; padding:3px">
        <a href="https://api.whatsapp.com/send?phone=+5492215437743">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" ></a>
    </div>
    