<div id="files" class="col-md-12 my-5">
    <div class="row">
        <div class="col-8">
            <p>Documentos</p>
        </div>

        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12"><input type="file"
                    id="file" name="file" maxlength="2"
                    (change)="getFiles($event)" multiple=""></div>
                <div class="col-md-6 my-2"><button (click)="downloadFiles()">Descargar archivos</button></div>
            </div>

        </div>
    </div>

</div>
