import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { ServiceService } from '../../firebase/service.service';
import { Router, NavigationEnd} from '@angular/router'

import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {


  properties;
  similar_properties;
  property;
  id : string;
  img = 0;
  imgCarousel = 0;
  cant_img_carrousel = []
  map_point;
  contador = 0;

  url;

  constructor(private db: AngularFirestore, private customer: ActivatedRoute, 
              private service: ServiceService,private router: Router, 
              private data: DataService) {
                

  }


  ngOnInit(): void {
    this.url = this.data.getUrl()
    console.log(this.url)

    this.customer.queryParams.subscribe(x=>{
      this.id= x.id;
    })

    

    this.property = this.service.getProperty(this.id);
    
    this.service.getProperty(this.id).subscribe(data=>{
      this.property = data;
      
      if(this.contador == 0){
        this.property.views = this.property.views +1
        console.log(this.property.views)
        this.service.updateProperty(this.property.key, this.property);
        this.contador++
      }
      
      for (let i = 0; i < this.property.cant_images; i++) {
        this.cant_img_carrousel.push(i)
        
      }
      this.db.collection('propiedades').valueChanges().subscribe(data=>{
        this.properties = data;
        
        this.map_point = "https://maps.google.com/maps?q=" + encodeURIComponent(String(this.property.map_point))+ "&t=k&z=18&ie=UTF8&iwloc=&output=embed";
        $("#map").attr("src",this.map_point)
        this.getSimilarProperties();
      });
      console.log(this.property)
    })

    
  }

  private getSimilarProperties(){
    
    //var similar_properties;
    //similar_properties = this.properties.filter( e => e.city === this.property.city);
    //this.similar_properties = similar_properties.filter( e => e.type_property === this.property.type_property);
    var x = this.properties
    x = x.filter( e => e.property_in.toLowerCase()  === this.property.property_in.toLowerCase() )
    x = x.filter( e => e.city.toLowerCase()  === this.property.city.toLowerCase() )
    this.similar_properties = x;

  }

  changeImg(button, img){
    this.imgCarousel = img;
    $("button").css("border","none");
    $("."+button).css("border", "2px solid #87a310");
    $(".img-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+img+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
  }

  showCarousel(){
    $(window).scrollTop(100);
    $("body").css("overflow-y", "hidden")
    $("#img-carrousel-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+this.imgCarousel+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
    $("#property").css("filter", "brightness(0.1)");
    $("#carousel").css("display","block");
  }

  closeCarousel(){
    $("body").css("overflow-y", "scroll")
    $("#property").css("filter", "brightness(1)");
    $("#carousel").css("display","none");
  }

  nextImg(){
    
    $("#prev").css("display","block");
    if(this.imgCarousel < this.property.cant_images-1){
      this.imgCarousel++;
      $("#img-carrousel-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+this.imgCarousel+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
    }else{
      $("#img-carrousel-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+this.imgCarousel+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
      $("#next").css("display","none");
    }
  }

  prevImg(){
    $("#next").css("display","block");
    if(this.imgCarousel > 0){
      this.imgCarousel--;
      $("#img-carrousel-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+this.imgCarousel+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
    }else{
      $("#img-carrousel-principal").attr("src", "https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/"+this.id+"%2F"+this.imgCarousel+"?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518");
      $("#prev").css("display","none");
    }
      
  }

}
