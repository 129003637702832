<div id="footer" class="col-md-12 col-12 row align-items-center font-1">
    <div class="col-md-6 col-12">
        <div class="col-md-12 d-flex justify-content-center">
            <img id="icono" src="../assets/images/Logo.png" class="col-md-6 img-fluid">
        </div>
        <p class="col-md-12 text-center">Paola Comesaña<br>Matillero & Contador Publico<br>Col. 7470</p>
    </div>
    <div class="col-md-6 col-12 m-0">    
        <div class="col-md-12 col-12 row">
            <img src="../../assets/images/whatsapp.png" class="col-md-4 col-4 iconos">
            <p class="col-md-8 col-8">(221) 505 8811 - (221) 543 7743</p>
        </div>
        <div class="col-md-12 row">
            <img src="../../assets/images/mail.png" class="col-md-4 col-4 iconos">
            <p class="col-md-8 col-8">amdq@yahoo.com.ar</p>
        </div>
        <div class="col-md-12 row">
            <img src="../../assets/images/facebook.png" class="col-md-4  col-4 iconos">
            <p class="col-md-8 col-8">paolacomesaña.propiedades</p>
        </div>
        <div class="col-md-12 row">
            <img src="../../assets/images/ubicacion.png" class="col-md-4 col-4 iconos">
            <p class="col-md-8 col-8">La Plata / Mar del Plata</p>
        </div>
    </div>
    <div class="col-md-12 text-center">
        <hr class="col-md-12" style="width:100%;">
        <p style="font-size: 0.8rem;">Desarrollado por <a  href="https://camilacatalini-ce6b9.web.app/">Camila Catalini</a> ©2021</p>
    </div>
</div>
