import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { RouterLink } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-all-properties',
  templateUrl: './all-properties.component.html',
  styleUrls: ['./all-properties.component.css']
})
export class AllPropertiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

  showProperty(){
    $("#properties").removeClass("d-flex");
    $('#properties').css('display','none')
    $('#property').css('display','block')
  }

}
