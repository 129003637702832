
<div id="my-carrousel mx-0 font-1">
    <div id="form-home" class="col-md-12  d-flex justify-content-center">
        <div id="form" class="col-md-8 col-12 text-center p-4 font-1">
            <h1 class="py-2"><b>Ofrecemos la propiedad que estás buscando.</b></h1>
            <div  class="col-md-12 mt-2">
                <div class="row d-flex justify-content-around justify-content-md-start">
                    <div class="form-group col-md-3 col-6 my-2 ">
                        <select class="form-control text-center" id="type-op" >
                        <option option="-">Operacion</option>
                        <option option="alquiler">alquiler</option>
                        <option option="venta">venta</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-6 my-2 ">
                        <select class="form-control text-center" id="city-op">
                        <option option="-">Ciudad/Partido</option>
                        <option option="laplata">La Plata</option>
                        <option option="loshornos">Los Hornos</option>
                        <option option="mardelplata">Mar del Plata</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-6 my-2">
                        <select class="form-control text-center" id="property-op">
                        <option option="-">Propiedad</option>
                        <option option="casa">casa</option>
                        <option option="dpto">dpto</option>
                        <option option="duplex">duplex</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-6 my-2">
                        <select class="form-control text-center" id="amb-op">
                        <option option="-">Ambientes</option>
                        <option option="1" type="number">1</option>
                        <option option="2" type="number">2</option>
                        <option option="3" type="number">3</option>
                        <option option="4" type="number">4</option>
                        </select>
                    </div>
                    <div class="col-md-12 col-6 my-2 d-flex justify-content-center">
                        <button style="background-color: #fbe307;" id="btn-search" class="col-md-2 p-md-2 p-1 text-center" (click)="choice()" >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <div id="carouselExampleIndicators"  class="carousel slide carousel-fade" data-ride="carousel">
        
      <div class="carousel-inner" *ngIf="properties" >
        <div class="carousel-item img-featured active">
                <img src="../../assets/images/fondo-image.jpg" class="d-block w-100 img-fluid">
            
        </div>
      </div>
      
    </div>
    
</div>