import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service';
import { Property } from '../../firebase/property-model.module';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-edit-database',
  templateUrl: './edit-database.component.html',
  styleUrls: ['./edit-database.component.css']
})
export class EditDatabaseComponent implements OnInit {

  property : Property = {
    'property_in': '-',
    'title': '-',
    'cost': 0,
    'description' : '-',
    'type_property' : '-',
    'city' : '-',
    'dir' : '-',
    'room' : 0,
    'toilet' : 0,
    'garden' : true,
    'garage' : true,
    'ambiente' : 0,
    'gas' : true,
    'agua' : true,
    'cloacas' : true,
    'energia' : true,
    'telefono' : true,
    'cable' : true,
    'key': '-',
    'map_point': '-',
    'show': true,
    'views': 0,
    'fecha': '-',
    'cant_images': 0
  };
  property_print;
  properties;
  images = [];
  map_point;

  constructor(private service: ServiceService) {
  }

  ngOnInit(): void {
    this.service.properties.subscribe(data=>{
      this.properties = data; 
     
    })
    
  }

  editProperty(property){

    this.property = property

    this.map_point = "https://maps.google.com/maps?q=" + encodeURIComponent(String(this.property.map_point))+ "&t=k&z=18&ie=UTF8&iwloc=&output=embed";
  

    $("#map").attr("src",this.map_point)
    $(".property").css('display','none')
    $("form").css('display','block')
  }

  loadProperty(){

    this.property.title= $("#title-p").val()
    this.property.cost = $("#cost").val()
    this.property.property_in = $("#property_in").val()
    this.property.antiquity = $("#antiquity").val()
    this.property.surface = $("#surface").val()

    this.property.description = $("#description").val()
    this.property.expenses = $("#expenses").val()
    this.property.cost_expenses = $("#cost-expenses").val()

    this.property.type_property = $("#property").val()
    this.property.city = $("#city").val()
    this.property.dir = $("#dir").val()
    this.property.ambiente = $("#ambiente").val()
    this.property.room = $("#room").val()
    this.property.toilet = $("#toilet").val()
    this.property.garden = $("#garden").val()
    this.property.garage = $("#garage").val()
    
    this.property.gas = $("#gas").val()
    this.property.agua = $("#agua").val()
    this.property.energia = $("#energia").val()
    this.property.cloacas = $("#cloacas").val()
    this.property.telefono = $("#telefono").val()
    this.property.cable = $("#cable").val()
    
    this.property.map_point = $("#map_point").val();
    this.property.show = true;
    
    this.service.updateProperty(this.property.key, this.property);

    console.log(this.property)

    $("#property-form").css("display","none");
    $("#list-properties").css("display","block");
    return false
  }

  changeImg(button){
    $("button").css("border","none");
    $("#"+button).css("border", "4px solid #87a310");
  }

  getImage(e){
    this.images.push({
      'name' : e.target.files[0].name,
      'file' : e.target.files[0]
    });
  }

  cancel(){
    $(".property").css('display','block')
    $("form").css('display','none')
    $(window).scrollTop(0);
  }

  printProperty(property) {

    this.property_print = property;

    if(this.property_print != null){
      var div = document.querySelector("#print");
      console.log(div)
      var ventana = window.open('', 'PRINT', 'height=700,width=1100');
      ventana.document.write('<html><head><title>' + document.title + '</title>');
      ventana.document.write('<link rel="stylesheet" href="style.css">');
      ventana.document.write('</head><body >');
      ventana.document.write(div.innerHTML);
      ventana.document.write('</body></html>');
      ventana.document.close();
      ventana.focus();
      ventana.onload = function() {
        ventana.print();
      };

    }
      
    return true;
  }

  deleteProperty(property){
    this.property = property;
    this.property.show = false;
    this.service.updateProperty(this.property.key, this.property);
  }

  recoverProperty(property){
    this.property = property;
    this.property.show = true;
    this.service.updateProperty(this.property.key, this.property);
  }

  public resetViews(){
    this.service.resetViews(this.properties);
  }

}
