import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private data: DataService) { }

  ngOnInit(): void {

    
    $("#home").css('height',$(window).height());
    
  }

  loadPage() {
    setTimeout(function(){$("form-ventas").css('display','block');},1000);
  }

  changeAlquiler(){
    $("#btn-alquiler").css('background-color','#87a310');
    $("#btn-venta").css('background-color','black');
  }

  changeVenta(){
    $("#btn-venta").css('background-color','#87a310');
    $("#btn-alquiler").css('background-color','black');
  }

}
