import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '../app/home/home.component';
import { AllPropertiesComponent } from '../app/all-properties/all-properties.component';
import { PropertyComponent } from '../app/property/property.component';
import { LotesComponent } from '../app/lotes/lotes.component';
import { ControlPanelComponent } from '../app/control-panel/control-panel.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'propiedades', component: AllPropertiesComponent },
  { path: 'lotes', component: LotesComponent },
  { path: 'propiedad', component: PropertyComponent },
  { path: 'admin', component: ControlPanelComponent },
  { path: '**', component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
