import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../firebase/service.service';

import { DataService } from '../shared/data.service';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css']
})
export class LotesComponent implements OnInit {

  constructor(private service: ServiceService,private data: DataService) { }

  lotes;
  url;

  ngOnInit(): void {
    this.url = this.data.getUrl()
    this.service.getLotes().subscribe(data=>{
      this.lotes = data;
      
    })
    
  }

}
