
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, NavigationEnd} from '@angular/router'
import { ServiceService } from '../../firebase/service.service'

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {

  public properties;
  properties_galery : Array<any> = [];
  public properties_pagination : Array<any> = [];
  public length_pagination;
  public var_btn : Array<any> = [];
  public number_btn = [0,1,2,3];
  public btn_press = 0;
  public cant_btn: Array<any> = [];
  public x;
  public missing_grids;
  private property_in = 'all';

  first_btn=0
  i = 0;

  //valores de ruta

  datesRuta;
  verifyRedirect = true

  private t = 'alquiler' //estado de propiedad: venta/alquiler
  private c = '-' //ciudad
  private p = '-' //tipo de propiedad
  private a = '-' //cantidad de ambientes

  constructor(private service: ServiceService,private router: Router) {
  }

  ngOnInit(): void {
    this.datesRuta = window.location.search;
    this.getProperty(this.service.getProperties())
    this.router.events.subscribe((event) => {
    })
    let params = new URLSearchParams(location.search);
    var contract = params.get('c');
  }

  showProperty(){
    $("#latest-properties").removeClass("d-flex");
    $('#latest-properties').css('display','none')
    $('#property').css('display','block')
  }

  private getProperty(properties){

    properties.subscribe(data=>{
      this.properties = data;
      this.x = data;

      this.properties.reverse()
      this.x.reverse()
      
      this.length_pagination = this.properties.length/4;

      this.getParamsUrl()

      

      //carga de array de array con 4 propiedades para la paginacion

      var c = 1;
      var xxx = 0
      var array_of_four_properties: Array<any> = [];
      this.var_btn.push(xxx)
      this.properties.forEach(element => {
        
        if(c<=12){
          
          array_of_four_properties.push(element)
          if(xxx==0){
            this.var_btn.push(2);
            xxx = 2;
          }else{
            xxx = xxx+xxx
            this.var_btn.push(xxx);
          }
          c++
        }else{
          this.properties_pagination.push(array_of_four_properties)
          array_of_four_properties = []
          array_of_four_properties.push(element)
          c = 2;
        }
        
      });

      if(array_of_four_properties!==null){
        this.properties_pagination.push(array_of_four_properties)
      }

      //divide la catidad de propiedades para crear la botonera
      const cant_pag = this.properties_pagination.length
      var btn = 0;
      for (var i=0; (i<cant_pag); i++) {
          this.cant_btn.push(btn)
          btn++
      }

      console.log(this.cant_btn)

      //******** */
      if($(window).width() > 767){
        this.properties_galery = this.properties.slice(0,12);
        this.missing_grids = new Array(12);
      }else{
        this.properties_galery = this.properties.slice(0,6);
        this.missing_grids = new Array(6);
      }
      
    })

  }

  private getParamsUrl(){
    //Creamos la instancia
    const urlParams = new URLSearchParams(this.datesRuta);

    //Accedemos a los valores
    var t = urlParams.get('t');
    var c = urlParams.get('c');
    var p = urlParams.get('p');
    var a = urlParams.get('a');

    if(t!==null && this.verifyRedirect){
      if((t!=='-')||(c!=='-')||(p!=='-')||(a!=='-')){

        var x = this.x;
        if(t != '-'){x = x.filter( e => e.property_in == t)}
        
        if(c != '-'){x = x.filter( e => e.city == c)}
        if(p != '-'){x = x.filter( e => e.type_property == p)}
        if(a != '-'){x = x.filter( e => e.ambiente == a)}
        this.properties_galery  = []
        this.properties = x;
        this.length_pagination = x.length/4;
        this.verifyRedirect = false
      }
    }

    
  }
  
  

  changeAlquiler(){
    this.property_in = 'alquiler';
    $("#search-all").css('background-color','#b30f17c2');
    $("#search-venta").css('background-color','#b30f17c2');
    $("#search-alquiler").css('background-color','#e80913');
    this.searchProperties();
  }

  changeVenta(){
    this.property_in = 'venta';
    $("#search-alla").css('background-color','#b30f17c2');
    $("#search-alquiler").css('background-color','#b30f17c2');
    $("#search-venta").css('background-color','#e80913');
    this.searchProperties();
  }

  changeAll(){
    this.property_in = 'all';
    $("#search-venta").css('background-color','#b30f17c2');
    $("#search-alquiler").css('background-color','#b30f17c2');
    $("#search-all").css('background-color','#e80913');
    this.searchProperties();
  }

  showSearch(){
    $(window).scrollTop(100);
    $('#latest-properties').css('display','none');
    $('#filtro').css('display','none');
    $('#pag').css('display','none');
    $('nav').css('display','none');
    $('#search').css('display','block')
    $('#icon-wspp').css('display','none')
  }

  closeSearch(){
    
    $('#latest-properties').css('display','block');
    $('#filtro').css('display','block');
    $('#pag').css('display','block');
    $('nav').css('display','block');
    $('#search').css('display','none')
    $('#icon-wspp').css('display','block')
  }

  searchProperties(){
    var x = this.x;
    var from = 0;
    var until = 999999999999999;
    if(($("#from").val() !== '')||($("#until").val() !== '')){
      if($("#from").val() !== ''){from = $("#from").val()}  
      if($("#until").val() !== ''){until = $("#until").val()}  
      console.log(from +'-'+ until)
      x = x.filter( e => (e.cost >= from && e.cost <= until))
    }


    if(this.property_in != 'all'){x = x.filter( e => e.property_in === this.property_in)}
    
    if($("#city-op").val() != '-'){x = x.filter( e => e.city === $("#city-op").val())}
    if($("#property-op").val() != '-'){x = x.filter( e => e.type_property == $("#property-op").val())}
    if($("#amb-op").val() != '-'){x = x.filter( e => e.ambiente == $("#amb-op").val())}
    if($("#room-op").val() != '-'){x = x.filter( e => e.room == $("#room-op").val())}
    if($("#toilet-op").val() != '-'){x = x.filter( e => e.toilet == $("#toilet-op").val())}
    if($("#expenses-op").val() != '-'){x = x.filter( e => e.expenses == $("#expenses-op").val())}
    this.properties_pagination  = []
    
    this.properties = x;
    
    this.length_pagination = x.length/4;

    //carga de array de array con 4 propiedades para la paginacion

    var c = 1;
    var xxx = 0
    this.var_btn = []
    var array_of_four_properties: Array<any> = [];
    this.var_btn.push(xxx)
    this.properties.forEach(element => {
      
      if(c<=12){
        
        array_of_four_properties.push(element)
        if(xxx==0){
          this.var_btn.push(2);
          xxx = 2;
        }else{
          xxx = xxx+xxx
          this.var_btn.push(xxx);
        }
        c++
      }else{
        this.properties_pagination.push(array_of_four_properties)
        array_of_four_properties = []
        array_of_four_properties.push(element)
        c = 2;
      }
      
    });

    if(array_of_four_properties!==null){
      this.properties_pagination.push(array_of_four_properties)
    }
    //divide la catidad de propiedades para crear la botonera
    const cant_pag = this.properties_pagination.length
    var btn = 0;
    this.cant_btn = []
    for (var i=0; (i<cant_pag && i<4); i++) {
      
        this.cant_btn.push(btn)
        btn++
        console.log(this.cant_btn)
    }

    $(".page-link").css('background-color','white');
    $(".page-link").css('color','black');
    $("#btn-"+"0").css('background-color','#e80913');
    $("#btn-"+"0").css('color','white');

    $(window).scrollTop(0);
    if($(window).width() < 768){
      this.closeSearch();
    }
  }

  prev(){
    $(window).scrollTop(150);
    if(this.i!==0){
      this.i--
      if(this.btn_press > this.first_btn){
        this.btn_press--
        $(".page-link").css('background-color','white');
        $(".page-link").css('color','black');
        $("#btn-"+this.btn_press).css('background-color','#e80913');
        $("#btn-"+this.btn_press).css('color','white');
        
      }else{
        this.btn_press--
        if(this.number_btn[3] !== 3){
          this.number_btn[0]--
          this.number_btn[1]--
          this.number_btn[2]--
          this.number_btn[3]--
        }
        
          $(".btn-li").css('display','none')
          $("#btn-li-"+(this.number_btn[0])).css('display','block')
          $("#btn-li-"+(this.number_btn[1])).css('display','block')
          $("#btn-li-"+(this.number_btn[2])).css('display','block')
          $("#btn-li-"+(this.number_btn[3])).css('display','block')
        
        
        $(".page-link").css('background-color','white');
        $(".page-link").css('color','black');
        $("#btn-"+this.btn_press).css('background-color','#e80913');
        $("#btn-"+this.btn_press).css('color','white');
      }
    }
  }

  next(){
    $(window).scrollTop(150);
    if(this.i<this.properties_pagination.length-1){
      this.i++;
      if(this.btn_press < 3){
        this.btn_press++
        $(".page-link").css('background-color','white');
        $(".page-link").css('color','black');
        $("#btn-"+this.btn_press).css('background-color','#e80913');
        $("#btn-"+this.btn_press).css('color','white');
        
      }else{
        this.first_btn++
        this.btn_press++
        this.number_btn[0]++
        this.number_btn[1]++
        this.number_btn[2]++
        this.number_btn[3]++
        $(".btn-li").css('display','none')
        $("#btn-li-"+(this.number_btn[0])).css('display','block')
        $("#btn-li-"+(this.number_btn[1])).css('display','block')
        $("#btn-li-"+(this.number_btn[2])).css('display','block')
        $("#btn-li-"+(this.number_btn[3])).css('display','block')
        $(".page-link").css('background-color','white');
        $(".page-link").css('color','black');
        $("#btn-"+this.btn_press).css('background-color','#e80913');
        $("#btn-"+this.btn_press).css('color','white');
      }
    }
  } 

  public changeProperties(btn){
    this.btn_press = btn
    
  
    $(".page-link").css('background-color','white');
    $(".page-link").css('color','black');
    $("#btn-"+this.btn_press).css('background-color','#e80913');
    $("#btn-"+this.btn_press).css('color','white');
    $(window).scrollTop(150);
  }

  clear(){
    $("#city-op").val('-')
    $("#property-op").val('-')
    $("#amb-op").val('-')
    $("#room-op").val('-')
    $("#toilet-op").val('-')
    $("#expenses-op").val('-')
    this.properties_pagination  = []
    this.cant_btn = []
    this.x = []
    this.getProperty(this.service.getProperties())
    $(window).scrollTop(0);
    this.cant_btn = []
    if($(window).width() < 768){
      this.closeSearch();
    }
  }


}
