import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service';
import { Property } from '../../firebase/property-model.module';

@Component({
  selector: 'app-upload-database',
  templateUrl: './upload-database.component.html',
  styleUrls: ['./upload-database.component.css']
})
export class UploadDatabaseComponent implements OnInit {

  property : Property = {};
  images = [];


  constructor(private service: ServiceService) {
  }

  ngOnInit(): void {
    

  }

  changeImg(button){
    $("button").css("border","none");
    $("#"+button).css("border", "4px solid #87a310");
  }

  loadProperty(){
    this.property.title= $("#title-u").val()
    this.property.cost = $("#cost-u").val()

    this.property.property_in = $("#property_in-u").val()
    this.property.antiquity = $("#antiquity-u").val()
    this.property.surface = $("#surface-u").val()

    this.property.description = $("#description-u").val()
    this.property.expenses = $("#expenses-u").val()
    this.property.cost_expenses = $("#cost-expenses-u").val() 
    console.log($("#expenses-u").val() +'...'+ this.property.expenses)
    this.property.type_property = $("#property-u").val()
    this.property.city = $("#city-u").val()
    this.property.dir = $("#dir-u").val()
    this.property.ambiente = $("#ambiente-u").val()
    this.property.room = $("#room-u").val()
    this.property.toilet = $("#toilet-u").val()
    this.property.garden = $("#garden-u").val()
    this.property.garage = $("#garage-u").val()
    this.property.cable = $("#antiquity-u").val()
    
    this.property.gas = $("#gas-u").val()
    this.property.agua = $("#agua-u").val()
    this.property.energia = $("#energia-u").val()
    this.property.cloacas = $("#cloacas-u").val()
    this.property.telefono = $("#telefono-u").val()
    this.property.cable = $("#cable-u").val()

    this.property.map_point = $("#point-u").val()
    this.property.show = true;
    this.property.views = 0;
    var date = new Date()
    this.property.fecha = date.getDate() + "-"+ date.getMonth()+ "-" +date.getFullYear();

    this.property.cant_images = this.images.length;

    this.service.loadProperty(this.property, this.images);

    $("#title-u").val('')
    $("#cost-u").val('')
    $("#precio-u").val('')

    $("#property_in-u").val('')
    $("#antiquity-u").val('')
     $("#surface-u").val('')

    $("#description-u").val('')

    $("#expenses-u").val('')
    $("#cost-expenses-u").val('')

    $("#property-u").val('')
    $("#city-u").val('')
    $("#dir-u").val('')
    $("#ambiente-u").val('')
    $("#room-u").val('')
    $("#toilet-u").val('')
    $("#garden-u").val('')
    $("#garage-u").val('')
    $("#antiquity-u").val('')
    
    $("#gas-u").val('')
    $("#agua-u").val('')
    $("#energia-u").val('')
    $("#cloacas-u").val('')
    $("#telefono-u").val('')
    $("#cable-u").val('')

    $("#point-u").val('')

    $('#image-1-u').val('')
    $('#image-2-u').val('')
    $('#image-3-u').val('')
    $('#image-4-u').val('')
    $('#image-5-u').val('')
    $('#image-6-u').val('')
    $('#image-7-u').val('')
    $('#image-8-u').val('')

    this.images = []

    return false;
  }

  getImage(e){
    var files = e.target.files

    for (let i = 0; i < files.length; i++) {
      this.images.push({
        'name' : files[i].name,
        'file' : files[i]
      });
    }
  }

  public cancel(){
    $("#title-u").val('')
    $("#cost-u").val('')

    $("#property_in-u").val('')
    $("#antiquity-u").val('')
     $("#surface-u").val('')

    $("#description-u").val('')
    $("#expenses-u").val('')
    $("#cost-expenses-u").val('')

    $("#property-u").val('')
    $("#city-u").val('')
    $("#dir-u").val('')
    $("#ambiente-u").val('')
    $("#room-u").val('')
    $("#toilet-u").val('')
    $("#garden-u").val('')
    $("#garage-u").val('')
    $("#antiquity-u").val('')
    
    $("#gas-u").val('')
    $("#agua-u").val('')
    $("#energia-u").val('')
    $("#cloacas-u").val('')
    $("#telefono-u").val('')
    $("#cable-u").val('')

    $("#point-u").val('')

    $('#image-1-u').val('')
    $('#image-2-u').val('')
    $('#image-3-u').val('')
    $('#image-4-u').val('')
    $('#image-5-u').val('')
    $('#image-6-u').val('')
    $('#image-7-u').val('')
    $('#image-8-u').val('')

    $("#uploadProperty").css("display","none");
    $("#editProperty").css("display","block");

    return false
  }



}
