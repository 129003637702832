
<div id="list-properties" class="col-md-12" *ngIf="properties">
    <div class="col-md-12 py-3 row">
        <div class="col-md-6">
            <p class="col-md-12 p-0 m-0 d-flex align-items-center" style="height: 50px;">Estado de propiedad : <b style="color: green">•</b>Activa<b style="color:red">•</b>Inactiva</p>
        </div>
        <div class="col-md-6 p-0 m-0" style="height: 50px;">
            <button (click)="resetViews()">Reiniciar vistas</button>
        </div>
    
    </div>
    <div id="list" class="row d-flex justify-content-around">
        <div class="col-md-5 m-1 p-0 property" *ngFor="let property of properties"  >
            <div class="row" [ngClass]="'show'+property.show">
                <div class="col-md-3 col-4 p-0 img-property">
                    <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{0}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid">
                </div>
                <div class="row col-md-9 col-8 pl-4">
                    <div class="col-md-12 row">
                        <div class="col-md-9">
                            <a [routerLink]="['/propiedad']" [queryParams]="{id: property.key}">{{property.title | slice:0:30}}...</a>
                        </div>
                        <div class="col-md-3 row">
                            <p>Vistas: {{property.views}}</p>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <p class="text-lowercase">{{property.description | slice:0:70}}...</p>
                        
                    </div>
                    <div class="col-md-12 d-flex justify-content-end">
                        <button class="col-md-3 m-1" (click)="editProperty(property)">Editar</button>
                        <button id="to_print" class="col-md-3 m-1" (click)="printProperty(property)" style="display: none;">Imprimir</button>
                        <button *ngIf="property.show" class="col-md-4 m-1" (click)="deleteProperty(property)">Eliminar</button>
                        <button *ngIf="!property.show" class="col-md-4 m-1" (click)="recoverProperty(property)">Recuperar</button>
                    </div>  
                </div>
            </div>
        </div>
        <div class="col-md-5 m-4 p-0 "></div>
    </div>   
    <form id="property-form" class="col-md-12 p-5" >
        <div class="row">
            <div class="col-md-7  row">
                <div class="col-md-8">
                    <input value="{{property.title}}" id="title-p" name="title-p" type="text" class="form-control"  aria-label="Example text with button addon" aria-describedby="button-title">
                 </div>
                <div class="col-md-4 text-end">
                    <input value="{{property.cost}}" id="cost" name="cost" type="text" class="form-control" placeholder="U$S Precio" aria-label="Example text with button addon" aria-describedby="button-cost">
                </div>
                <div class="col-md-12 text-justify">
                    <p style="color: #87a310;"><b>Descripcion</b></p>
                    <p class="col-md-12 text-justify"><textarea value="{{property.description}}" id="description" name="description" class="col-md-12 text-justify"></textarea></p>
                </div>
                <div class="col-md-12 row">
                    <p style="color: #87a310;"><b>Detalle</b></p>
                    <div class="col-md-4">
                        <p class="col-md-12"><b>Precio: </b><input value="{{property.cost}}" id="cost" name="cost"  type="text" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Ciudad/Partido: </b><input value="{{property.city}}" id="city" name="city" type="text" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Expensas: </b><select class="form-control" name="expenses" id="expenses">
                            <option value="si">Si</option>
                            <option value="no">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Monto: </b><input value="{{property.const-expenses}}" id="cost-expenses" name="cost-expenses" type="text"></p>
                        
                    </div>
                    <div class="col-md-4">
                        <p class="col-md-12"><b>Direccion: </b><input value="{{property.dir}}" id="dir" name="dir"  type="text" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Tipo de propiedad: </b><input value="{{property.type_property}}" id="property" name="property"  type="text" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Habitaciones: </b><input value="{{property.room}}" id="room" name="room"  type="number" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Baños: </b><input value="{{property.toilet}}" id="toilet" name="toilet"  type="number" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                    </div>
                    <div class="col-md-4">
                        <p class="col-md-12"><b>Ambientes: </b><input value="{{property.ambiente}}" id="ambiente" name="ambiente"  type="number" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        <p class="col-md-12"><b>Patio: </b><select class="form-control" name="garden" id="garden">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Garage: </b><select class="form-control" name="garage" id="garage">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                    </div>
                </div>
                <div class="col-md-12 row">
                    <p style="color: #87a310;"><b>Servicios</b></p>
                    <div class="col-md-6">
                        <p class="col-md-12"><b>Gas: </b><select class="form-control" name="gas" id="gas">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Agua: </b><select class="form-control" name="agua" id="agua">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Energia: </b><select class="form-control" name="energia" id="energia">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        
                    </div>
                    <div class="col-md-6">
                        <p class="col-md-12"><b>Cloacas: </b><select class="form-control" name="cloacas" id="cloacas">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Telefono: </b><select class="form-control" name="telefono" id="telefono">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                        <p class="col-md-12"><b>Cable: </b><select class="form-control" name="cable" id="cable">
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </select></p>
                    </div>
                </div>
            </div>
            
            <div class="col-md-5 ">
                <div class="col-md-12 my-5">
                <div class="row d-flex justify-content-around">
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Tipo de operacion</b>
                        <input value="{{property.property_in}}" id="property_in" name="property_in" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Antiguedad</b>
                        <input value="{{property.antiquity| slice:0:3}}" id="antiquity" name="antiquity" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Superficie cubierta</b>
                        <input value="{{property.surface | slice:0:4}}" id="surface" name="surface" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                </div>
            </div>
                <div class="d-flex justify-content-center">
                    <div class="col-md-10 row ">
                        <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{0}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="col-md-12"  id="img-principal" class="img-fluid">
                        <div class="col-md-12 my-4">
                            
                            <button type="file" id="img-1" class="col-md-3 p-1" (click)="changeImg('img-1')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{0}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-2" class="col-md-3 p-1" (click)="changeImg('img-2')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{1}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-3" class="col-md-3 p-1" (click)="changeImg('img-3')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{2}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-4" class="col-md-3 p-1" (click)="changeImg('img-4')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{3}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-5" class="col-md-3 p-1" (click)="changeImg('img-5')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{4}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-6" class="col-md-3 p-1" (click)="changeImg('img-6')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{5}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-7" class="col-md-3 p-1" (click)="changeImg('img-7')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{6}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button id="img-8" class="col-md-3 p-1" (click)="changeImg('img-8')"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F{{7}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
    
                        
                            <p>Imagen principal</p>
                            <input type="file"
                                id="image-0-u" name="image-1-u"
                                accept="image/png, image/jpeg" maxlength="2"
                                (change)="getImage($event)">
                            <p>Otras imagenes</p>
                            <input type="file"
                                id="image-1-u" name="image-1-u"
                                accept="image/png, image/jpeg" maxlength="2"
                                (change)="getImage($event)" multiple="">
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div class="col-md-12 row">
            <!--Google map-->
            <div id="map-container-google-2" class="col-md-8 z-depth-1-half map-container-3 p-2" style="height: 400px;">
               <iframe id="map" src="#" frameborder="0"
                 style="border:0;" allowfullscreen></iframe>
                 <p class="col-md-4"><b>Punto: </b><input value="{{property.map_point}}" id="map_point" name="map_point"  type="text" class="form-control" placeholder="{{property.map_point}}" aria-label="Example text with button addon" aria-describedby="button-title"></p>
            </div>
           <!--Google Maps--> 

        </div>
        <div class="row d-flex justify-content-center">
            <p class="col-md-1"><input type="submit" value="Cancelar" (click)="cancel()"></p>
            <p class="col-md-1"><input type="submit" value="Cargar Propiedad" (click)="loadProperty()"></p>
        </div>
        </div>
    </form>
</div>
<div id="print" style="background-color: white; margin-top: 30px;" *ngIf="property_print">
    <div >
        <div style="display: flex; justify-content: space-between; height: 150px; background-color: #f50b12;">
            <div style="float: left; width: 400px;">
                <img style="height: 150px; width: 150px; float: left;" src="../../assets/images/Logo2.png">
                <p style="font-size: 1.5rem; text-align: center; margin-top: 45px;">Comesaña Propiedades</p>
                <p style="font-size: 1rem; text-align: center;">Col. 7470</p>
            </div>
            <div style="margin-top: 25px; margin-right: 30px;">
                <div>
                    <h1 style="font-size: 4rem;"> {{property_print.property_in}} | {{property_print.type_property}} </h1>
                </div>
                
            </div>
        </div>
        <div style=" padding-top: 30px;position: absolute; width: 95vw; margin-right: 50px; float: right; z-index: 0;">
            <p style="font-size: 1.8rem; text-align: right;"> {{property_print.title}} - {{property_print.city}}</p>
            <p style="font-size: 1.8rem; text-align: right;"> U$s {{property_print.cost}}</p>
            <div style="display: flex; position: absolute; right: 0;">
                <img style="float: left;height: 25px; width: 25px; margin: 0 3px;" src="../../assets/images/icon-ambiente.png">
                <p style="float: left; margin: 2px 3px;">{{property_print.ambiente}} amb.</p>
                <img style="float: left;height: 25px; width: 25px; margin: 0 3px;" src="../../assets/images/icon-room.png">
                <p style="float: left; margin: 2px 3;">{{property_print.room}} dorm.</p>
                <img style="float: left;height: 25px; width: 25px; margin: 0 3px;" src="../../assets/images/icon-toilet.png">
                <p style="float: left; margin: 2px 3;">{{property_print.toilet}} baños.</p>
            </div>
        </div>
        <div style="margin: 0 10px; position: relative; top:90px; float: inline-end; z-index: 3;">
            <img style="height: 320px; width: 260px; margin: 5px; display:inline-block; vertical-align: bottom;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property_print.key}}%2F{{0}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
            <img style="height: 190px; width: 175px; margin: 5px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property_print.key}}%2F{{1}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
            <img style="height: 190px; width: 175px; margin: 5px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property_print.key}}%2F{{2}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
            <img style="height: 190px; width: 175px; margin: 5px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property_print.key}}%2F{{3}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
            <img style="height: 190px; width: 175px; margin: 5px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property_print.key}}%2F{{4}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">
        </div>
        <div style="background-color: #f50b12; height: 60px; position: relative; top:95px;" class="text-center">
            <img style="float: left;height: 25px; width: 25px" src="../../assets/images/whatsapp.png">
            <p style="float: left;">(221) 505 8811</p>
            <img style="float: left;height: 25px; width: 25px" src="../../assets/images/whatsapp.png">
            <p style="float: left;">(221) 543 7743</p>
        </div>
    </div>
</div>

