import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AllPropertiesComponent } from './all-properties/all-properties.component';
import { PropertyComponent } from './property/property.component';
import { CarrouselFeaturedComponent } from './carrousel-featured/carrousel-featured.component';
import { LatestPropertiesComponent } from './latest-properties/latest-properties.component';
import { PropertiesComponent } from './properties/properties.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';

import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { environment } from './../environments/environment';
import { FooterComponent } from './footer/footer.component';
import { UploadDatabaseComponent } from './upload-database/upload-database.component';
import { EditDatabaseComponent } from './edit-database/edit-database.component';
import { EditCarouselComponent } from './edit-carousel/edit-carousel.component';
import { LotesComponent } from './lotes/lotes.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { UploadLoteComponent } from './upload-lote/upload-lote.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AllPropertiesComponent,
    PropertyComponent,
    CarrouselFeaturedComponent,
    LatestPropertiesComponent,
    PropertiesComponent,
    ControlPanelComponent,
    FooterComponent,
    UploadDatabaseComponent,
    EditDatabaseComponent,
    EditCarouselComponent,
    LotesComponent,
    UploadFilesComponent,
    UploadLoteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule // imports firebase/storage only needed for storage features
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
