<div id="lotes" class="col-md-12 ">
    <h1 class="col-md-12 text-center" >Lotes</h1>
    <div class="row d-flex justify-content-center justify-content-md-between">
        <div class="col-3 lote m-1" *ngFor="let lote of lotes;index as i" [ngClass]="'show'+!lote.hidden">
            <div >
                <p style="color: #e80913;">LOTE {{i+1}}:
                    <b *ngIf="lote.availability; else availability_falso" class="m-0 p-0">En venta</b>
                    <ng-template #availability_falso><b class="m-0 p-0">Vendido</b></ng-template>
                </p>
                <div class="col-md-12">
                    <b><p class="text-lowercase">{{lote.description}}</p></b>
                </div>
                <div class="col-md-12 consulta text-end">
                    <a href="https://api.whatsapp.com/send?phone=+5492215437743&text=CONSULTA POR LOTE:%0A%0A{{lote.description}}">Consultar</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 lote mx-1 my-2" style="visibility: hidden; position: relative; width: 200px;" *ngFor="let property of [0,1,2,3,4]">
        </div>
        <div class="col-md-12 d-flex justify-content-center">
            <div id="shared" class="col-md-6 row d-flex justify-content-center">
                <p class="col-md-3"><b>Compartir:</b></p>
                <a class="col-md-3 text-center" href="https://www.facebook.com/sharer/sharer.php?u={{url}}/lotes">Facebook</a>
                <a class="col-md-3 text-center" href="https://twitter.com/intent/tweet?text=Comesaña Propiedades Lotes:%0A%0A&url={{url}}">Twitter</a>
                <a class="col-md-3 text-center" href="https://api.whatsapp.com/send?text=Comesaña Propiedades Lotes:%0A%0A{{url}}/lotes">WhatsApp</a>
            </div>
            
        </div>
    </div>
</div>
