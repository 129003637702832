
<div  class="col-md-12 d-flex justify-content-center my-0 py-0" style="background-color: rgba(255, 255, 255, 0.945);;">
    <div id="form-ventas" class="form-ventas col-md-12 row">
        <div class="col-md-12 col-12">
            <app-carrousel-featured></app-carrousel-featured>
        </div>
        <div id="properties" class="col-md-12 py-4 px-0" style="background-color: white;">
            <app-latest-properties></app-latest-properties>
        </div>
        <div id="about-us" class="col-md-12 d-flex justify-content-center py-4 m-0 font-1">
            <div class="col-md-10">
                <h2 id="title-about" class="col-md-12 text-center py-3">Paola Comesaña Propiedades</h2>
                <div class="row">
                    <div class="col-md-8 col-12 row d-flex justify-content-between">
                        <div class="col-md-12 col-12">
                            <p class="text-justify p-3 ">
                                Comprar o alquilar una casa es una de las operaciones más importantes en la vida
                                de una persona. Nosotros lo sabemos bien, y sabemos que son muchos los sueños 
                                que se depositan en la compra o venta de una propiedad.
                                <br>
                                <br>
                                Por eso, atacando precisamente el aspecto más emocional de esta aventura, 
                                nuestra inmobiliaria pone el dedo en la llaga: allí donde estén tus sueños,
                                <b>Paola Comesaña Propiedades</b> podrá hacerlos realidad.
                            </p>
                        </div>
                        <div class="col-md-5 my-1">
                            <img class="img-about" src="../../assets/images/about-us-img-1.jpg">
                        </div>
                        <div class="col-md-5 my-1">
                            <img class="img-about" src="../../assets/images/about-us-img-2.jpg">
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center py-3 row text-center">
                        <div class="col-md-12 ">
                            <p >Nuestros servicios</p>
                        </div>
                        <div class="col-md-8 text-left mx-auto my-1">
                            <ul class="text-center">
                                <li>Compra</li>
                                <li class="my-1">Venta</li>
                                <li>Tasaciones</li>
                                <li class="my-1">Administracion de propiedades</li>
                                <li>Planos</li>
                                <li class="my-1">Empadronamiento</li>
                                <li>Agrimensura</li>
                                <li class="my-1">Estados Parcelarios</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-12 text-center py-4">
                        <p id="frase-about">“Juntos encontraremos el hogar de tus sueños”</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    
</div>

<div id="icon-wspp" style="position:fixed; right:10px; bottom:10px; text-align:center; padding:3px; z-index: 10;">
    <a href="https://api.whatsapp.com/send?phone=+5492215437743">
    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" ></a>
</div>