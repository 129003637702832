<div class="menu row p-0 m-0 font-1">
    <a href="/" class="col-md-4 col-12 row d-flex justify-content-center align-items-center link-menu">
        <img id="icono" src="../assets/images/Logo2.png" class="col-md-2 img-fluid">
        <div class="col-md-8 col-6">
            <h1 id="title" class="col-md-12 text-center p-0 m-0"> Comesaña Propiedades <br> <b class="p-p m-0" >Col. 7470</b>  </h1>
        </div>
    </a>
    <div id="menu-links-mbl" class="col-12  p-0 m-0">
        <div class="row d-flex justify-content-center align-items-center">
            <a href="/propiedades" type="button" class="col-4 text-center link-menu">PROPIEDADES</a>
            <a href="/lotes" type="button" class="col-2 text-center link-menu">LOTES</a>
            <a (click)="aboutUs()" type="button" id="about-us-mbl" class="col-3 text-center link-menu">NOSOTROS</a>
            <a (click)="footer()" type="button" class="col-3 text-center link-menu">CONTACTO</a>
        </div>    
    </div>
    <div id="menu-links" class="col-md-8 row d-flex justify-content-center align-items-center">
        <a href="/propiedades?t=alquiler&c=-&p=-&a=-" type="button" class="col-md-2 text-center link-menu">ALQUILERES</a>
        <a href="/propiedades?t=venta&c=-&p=-&a=-" type="button" class="col-md-2 text-center link-menu">VENTAS</a>
        <a href="/lotes" type="button" class="col-md-2 text-center link-menu">LOTES</a>
        <a (click)="aboutUs()" type="button" id="about-us" class="col-md-2 text-center link-menu">NOSOTROS</a>
        <a (click)="footer()" type="button" class="col-md-2 text-center link-menu">CONTACTO</a>
    </div>
</div>
<router-outlet ></router-outlet>

<app-footer></app-footer>