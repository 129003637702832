import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service'

@Component({
  selector: 'app-latest-properties',
  templateUrl: './latest-properties.component.html',
  styleUrls: ['./latest-properties.component.css']
})
export class LatestPropertiesComponent implements OnInit {


  public properties;
  public properties_galery;
  public screen_with;

  constructor(private service: ServiceService) { }

  ngOnInit(): void {
    this.getProperty(this.service.getProperties())
    this.screen_with = window.innerWidth;
  }

  private getProperty(properties){
    properties.subscribe(data=>{
      this.properties = data

      this.properties.reverse()
      
      if($(window).width() > 780){
        this.properties_galery = this.properties.slice(0,21);
      }else{

        
        this.properties_galery = this.properties.slice(0,15);
      }
      
    })
  }

}
