<div id="login" class="col-md-12 py-4 ">
    <div class="row d-flex justify-content-center">
        <p class="col-md-12 text-center">Paola Comesaña Propiedades</p>
        <div class="col-md-12 text-center row d-flex justify-content-center">
            <label class="col-md-12" for="pwd">Contraseña:</label>
            <input class="col-md-2" type="password" id="pass" name="pass">
            
        </div>
        <button class="col-md-1 my-1" (click)="login()">Entrar</button>
    </div>
    
</div>

<div id="show-panel">
    <div class="col-md-12">
        <div class="row d-flex justify-content-lg-between">
            <button class="col-md-3" (click)="changeForm('editProperty')">Editar Propiedad</button>
            <button class="col-md-3" (click)="changeForm('uploadProperty')">Cargar Propiedad</button>
            <button class="col-md-3" (click)="changeForm('uploadLote')">Cargar Lote</button>
            <button class="col-md-3" (click)="changeForm('uploadFiles')" disabled>Subir/descargar archivos</button>
        </div>
    </div>
    <app-edit-database class='form' id="editProperty"></app-edit-database>
    <app-upload-database class='form' id="uploadProperty"></app-upload-database>
    <app-upload-files class='form' id="uploadFiles"></app-upload-files>
    <app-upload-lote class='form' id="uploadLote"></app-upload-lote>
</div>



