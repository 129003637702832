<div id="latest-properties" class="">
    <div class="col-lg-12 col-md-12 col-sm-12 p-0 m-0">
        <div class="col-md-12 my-4 d-flex justify-content-between">
            <p class="col-md-6 col-12 px-3" style="font-size: 1.5rem; color:  #e80913  ;"><b>Ultimas propiedades</b></p>
            <div class="ver-mas col-md-1">
                <a [routerLink]="['/propiedades']" style="text-decoration: none;">Ver mas</a>
            </div>
        </div>

        <div class="row d-flex justify-content-center my-3 ">
            <div class="col-lg-3 col-md-3 col-sm-3 m-md-2 m-1 row propertie-img" *ngFor="let property of properties_galery">
                <div class="col-12">
                    <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518">   
                </div>
                <div class="info-p col-12">
                    <a class="col-12" [routerLink]="['/propiedad']"  [queryParams]="{id: property.key}"><b>{{property.title | slice:0:20}}...</b></a>
                    <p class="col-12 my-1">
                        <b class="text-lowercase">{{property.description | slice:0:80 }}...</b>
                    </p>
                    <div class="row icon pt-1 px-1 justify-content-end">
                        <img *ngIf="screen_with>480" src="../../assets/images/icon-ambiente.png" class="img-fluid">
                        <p class="col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.ambiente}} amb.</b></p>
                        <img *ngIf="screen_with>480" src="../../assets/images/icon-room.png" class="img-fluid">
                        <p class="col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.room}} dorm.</b></p>
                        <img *ngIf="screen_with>480" src="../../assets/images/icon-toilet.png" class="img-fluid">
                        <p class=" col-md-3 col-4 text-center px-lg-0 px-md-0 px-1"><b>{{property.toilet}} baños.</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="ver-mas-mbl col-12 px-3 py-3 py-md-0 text-end">
            <a [routerLink]="['/propiedades']" style="text-decoration: none;">Ver mas</a>
        </div>
    </div>
    
</div>
