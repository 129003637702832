<form id="property-form-u" class="col-md-12 p-5 ">
    <div class="row">
        <div class="col-md-7 row">
            <div class="col-md-8">
                <input id="title-u" name="title-u" type="text" class="form-control" placeholder="Titulo de Propiedad" >
             </div>
            <div class="col-md-4 text-end">
                <input id="cost-u" name="cost-u" type="text" class="form-control" placeholder="U$S Precio">
            </div>
            <div class="col-md-12 text-justify">
                <p style="color: #87a310;"><b>Descripcion</b></p>
                <textarea id="description-u" name="description-u" class="col-md-12 text-justify" rows="3"></textarea>
            </div>
            <div class="col-md-12 row">
                <p style="color: #87a310;"><b>Detalle</b></p>
                <div class="col-md-4">
                    <p class="col-md-12"><b>Precio: </b><input id="precio-u" name="precio-u"  type="text" class="form-control" placeholder="?" ></p>
                    <p class="col-md-12"><b>Expensas: </b><select class="form-control" name="expenses-u" id="expenses-u">
                        <option value="si">Si</option>
                        <option value="no">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Monto: </b><input id="cost-expenses-u" name="cost-expenses-u" type="text"></p>
                    <p class="col-md-12 py-2"><b>Ciudad/Partido: </b><input id="city-u" name="city-expenses-u" type="text" class="form-control"></p>
                   
                </div>
                <div class="col-md-4">
                    <p class="col-md-12"><b>Direccion: </b><input id="dir-u" name="dir-u"  type="text" class="form-control"></p>
                    <p class="col-md-12"><b>Tipo de propiedad: </b><input id="property-u" name="property-u"  type="text" class="form-control" placeholder="?"></p>
                    <p class="col-md-12"><b>Habitaciones: </b><input id="room-u" name="room-u"  type="number" class="form-control" placeholder="?"></p>
                    <p class="col-md-12"><b>Baños: </b><input id="toilet-u" name="toilet-u"  type="number" class="form-control" placeholder="?"></p>
                    
                </div>

                <div class="col-md-4">
                    <p class="col-md-12"><b>Ambientes: </b><input id="ambiente-u" name="ambiente-u"  type="number" class="form-control" placeholder="?"></p>
                    <p class="col-md-12"><b>Patio: </b><select class="form-control" name="garden-u" id="garden-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Garage: </b><select class="form-control" name="garage-u" id="garage-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                </div>
            </div>
            <div class="col-md-12 row">
                <p style="color: #87a310;"><b>Servicios</b></p>
                <div class="col-md-6">
                    <p class="col-md-12"><b>Gas: </b><select class="form-control" name="gas-u" id="gas-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Agua: </b><select class="form-control" name="agua-u" id="agua-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Energia: </b><select class="form-control" name="energia-u" id="energia-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    
                </div>
                <div class="col-md-6">
                    <p class="col-md-12"><b>Cloacas: </b><select class="form-control" name="cloacas-u" id="cloacas-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Telefono: </b><select class="form-control" name="telefono-u" id="telefono-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                    <p class="col-md-12"><b>Cable: </b><select class="form-control" name="cable-u" id="cable-u">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                      </select></p>
                </div>
            </div>
        </div>
        <div class="col-md-5 ">
            <div class="col-md-12 my-5">
                <div class="row d-flex justify-content-around">
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Tipo de operacion</b>
                        <input id="property_in-u" name="property_in-u" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Antiguedad</b>
                        <input id="antiquity-u" name="antiquity-u" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #87a310;">Superficie cubierta</b>
                        <input id="surface-u" name="surface-u" type="text" class="col-md-12 form-control" placeholder="?">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="col-md-10 row ">
                    <p>Imagen principal</p>
                    <input type="file"
                        id="image-0-u" name="image-1-u"
                        accept="image/png, image/jpeg" maxlength="2"
                        (change)="getImage($event)">
                    <p>Otras imagenes</p>
                    <input type="file"
                        id="image-1-u" name="image-1-u"
                        accept="image/png, image/jpeg" maxlength="2"
                        (change)="getImage($event)" multiple="">
                
                    <div class="col-md-12 row my-3">
                        <p>Mapa</p>
                        <!--Google map-->
                        <div id="map-container-google-2" class="col-md-8 z-depth-1-half map-container-3 p-2" style="height: 300px;">
                            <iframe src="https://maps.google.com/maps?q=-34.95031798164595, -57.96710531534143&t=k&z=18&ie=UTF8&iwloc=&output=embed" frameborder="0"
                                style="border:0;" allowfullscreen></iframe>
                                <p class="col-md-8"><b>Ingresar punto: </b><input value="" id="point-u" name="point-u"  type="text" class="form-control" placeholder="?" aria-label="Example text with button addon" aria-describedby="button-title"></p>
                        </div>
                        <!--Google Maps--> 
                    </div>
                </div>
                
            </div>
            
        </div>
        
        <div class="col-md-12 text-center">
            <p><input type="submit" value="Cargar Propiedad" (click)="loadProperty()"></p>
            <p><button (click)="cancel()">Cancelar</button></p>
        </div>
    </div>
    
</form>
    