import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {

  files = []

  constructor(private service: ServiceService) { }

  ngOnInit(): void {
  }

  public getFiles(e){
    console.log(files)

    var files = e.target.files

    for (let i = 0; i < files.length; i++) {
      this.files.push({
        'name' : files[i].name,
        'file' : files[i]
      });
    }

    console.log(this.files)
    this.service.loadFiles(this.files)

  }

  public downloadFiles(){
    console.log(this.service.getFiles())
  }

}
