import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ServiceService } from '../../firebase/service.service';
import { Lote } from '../../firebase/lote-module.module';

@Component({
  selector: 'app-upload-lote',
  templateUrl: './upload-lote.component.html',
  styleUrls: ['./upload-lote.component.css']
})
export class UploadLoteComponent implements OnInit {

  lote : Lote = {}
  lotes;

  constructor(private service: ServiceService) { }

  ngOnInit(): void {
    this.service.getLotes().subscribe(data=>{
      this.lotes = data;
      console.log(data)
    })
  }

  public uploadLote(){
    this.lote.description = $("#description-lote").val()
    this.lote.availability = true;
    this.service.loadLote(this.lote)
    $("#description-lote").val('')
  }

  public cancelUploadLote(){
    $("#description-lote").val('')
  }

  public hiddenLote(key,date){
    var lote : Lote = {}
    lote = date;
    lote.hidden = !(date.hidden);
    this.service.updateLote(key,lote)

  }

  public sellLote(key,date){
    var lote : Lote = {}
    lote = date;
    lote.availability = !(date.availability);
    this.service.updateLote(key,lote)

  }
}
