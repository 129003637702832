<div id="lote" class="col-md-12 py-4">
    <p>Descripcion del lote</p>
    <div class="col-md-12 text-justify">
        <textarea id="description-lote" name="description-lote" class="col-md-12 text-justify" rows="3"></textarea>
    </div>
    <div class="col-md-12 d-flex justify-content-lg-center">
        <button class="mx-1" (click)="uploadLote()">Cargar</button>
        <button class="mx-1" (click)="cancelUploadLote()">Cancelar</button>
    </div>
</div> 
<div id="lotes" class="col-md-12 p-4">
    <h1 class="col-md-12 text-center" >Lotes</h1>
    <div class="row d-flex justify-content-between">
        <div class="col-md-3 lote my-2" *ngFor="let lote of lotes;index as i">
            <div [ngClass]="'show'+!lote.hidden">
                <p style="color: #e80913; font-size: 1.5rem;">LOTE {{i+1}}: 
                    <b *ngIf="lote.availability; else availability_falso" class="m-0 p-0">En venta</b>
                    <ng-template #availability_falso><b class="m-0 p-0">Vendido</b></ng-template>
                </p>
                <div class="col-md-12">
                    <b><p>{{lote.description}}</p></b>
                </div>
                <div id="btn-edit" class="col-md-12 row mb-2  d-flex justify-content-end">
                    <button class="col-md-4" (click)="hiddenLote(lote.key,lote)">
                        <p class="m-0 p-0" *ngIf="lote.hidden; else hidden_falso">Mostrar</p>
                        <ng-template #hidden_falso><p class="m-0 p-0">Ocultar</p></ng-template>
                    </button>
                    <button class="col-md-4" (click)="sellLote(lote.key,lote)">
                        <p *ngIf="lote.availability; else availability_falso" class="m-0 p-0">Vendido</p>
                        <ng-template #availability_falso><p class="m-0 p-0">En venta</p></ng-template>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-3 lote my-2" style="visibility: hidden; position: relative; width: 300px;" *ngFor="let property of [0,1,2,3]">
        </div>
    </div>
    
</div>