<head>
    <meta property="og:url" content="https://comesañapropiedades.com.ar" />
    <meta property="og:title" content="Los Tiempos Cambian" />
    <meta property="og:description" content="holaaa" />
    </head>

<div id="property" class="col-lg-12 px-4 py-4" *ngIf="properties">
    <div class="row x">
        <div class="col-lg-7 col-md-12 col-sm-12 row">
            <div class="col-lg-8 col-sm-12 "><p class="fs-3" id="title"><b>{{property.title}}</b></p> </div>
            <div class="col-lg-4 text-end">
                <p id="cost" ><b class="fs-2">U$S {{property.cost}}</b><br><b *ngIf="property.expenses=='si'">(+{{property.cost_expenses}} Expensas)</b></p>
            </div>
            
            <div id="galery-mbl" class="col-sm-12">
                <div class="d-flex justify-content-center">
                    <div class="row ">
                        <img (click)="showCarousel()" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F{{img}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518"  class="img-principal col-md-12" >
                        <div class="col-md-12 my-4" >
                            <button *ngFor="let i of [0,1,2,3,4,5,6]" class="img-{{i}} col-3 p-1" (click)="changeImg('img-'+i, i)"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F{{i}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class="img-fluid" ></button>
                            <button  class="img-7 col-3 p-1" (click)="changeImg('img-7', 7)" disabled> <img src="../../assets/images/42cc1e87-e3a3-4e9c-a7ae-c3a21ca6c58e.jpg" class="img-fluid" ></button>
                        </div>                    
                    </div>               
                </div>            
            </div>
            <div class="col-md-12 text-justify">
                <p style="color: #e80913;"><b>Descripcion</b></p>
                <p class="col-md-12 text-justify">{{property.description.toLowerCase()}}</p>
            </div>
            
            <div class="col-md-12 col-8 row">
                <p style="color: #e80913;"><b>Detalle</b></p>
                <div class="col-md-4">
                    <p class="col-md-12"><b>Precio: </b>U$S{{property.cost}}</p>
                    <p class="col-md-12"><b>Expensas: </b>{{property.expenses}}</p>
                    <p class="col-md-12"><b>Ciudad/Partido: </b>{{property.city}}</p>
                    <p class="col-md-12"><b>Direccion: </b>{{property.dir}}</p>
                </div>
                <div class="col-md-4">
                    <p class="col-md-12"><b>Tipo de propiedad: </b>{{property.type_property}}</p>
                    <p class="col-md-12"><b>Habitaciones: </b>{{property.room}}</p>
                    <p class="col-md-12"><b>Baños: </b>{{property.toilet}}</p>
                    <p class="col-md-12"><b>Ambientes: </b>{{property.ambiente}}</p>
                </div>

                <div class="col-md-4">
                    
                    <p class="col-md-12" *ngIf="property.garden == 'true'; else garden_falso"><b>Patio: </b>Si</p>
                    <ng-template #garden_falso><p class="col-md-12"><b>Patio: </b>No</p></ng-template>
                    <p class="col-md-12" *ngIf="property.garage == 'true'; else garage_falso"><b>Garage: </b>Si</p>
                    <ng-template #garage_falso><p class="col-md-12"><b>Garage: </b>No</p></ng-template>
                </div>
            </div>
            <div class="col-md-12 col-4 row">
                <p style="color: #e80913;"><b>Servicios</b></p>
                <div class="col-md-4">
                    <p class="col-md-12" *ngIf="property.gas == 'true'; else gas_falso"><b>Gas: </b>Si</p>
                    <ng-template #gas_falso><p class="col-md-12"><b>Gas: </b>No</p></ng-template>
                    <p class="col-md-12" *ngIf="property.agua; else agua_falso"><b>Agua: </b>Si</p>
                    <ng-template #agua_falso><p class="col-md-12"><b>Agua: </b>No</p></ng-template>
                </div>
                <div class="col-md-4">
                    <p class="col-md-12" *ngIf="property.energia == 'true'; else energia_falso"><b>Energia: </b>Si</p>
                    <ng-template #energia_falso><p class="col-md-12"><b>Energia: </b>No</p></ng-template>
                    <p class="col-md-12" *ngIf="property.cloacas == 'true'; else cloacas_falso"><b>Cloacas: </b>Si</p>
                    <ng-template #cloacas_falso><p class="col-md-12"><b>Cloacas: </b>No</p></ng-template>
                </div>
                <div class="col-md-4">
                    <p class="col-md-12" *ngIf="(property.telefono == 'true'); else telefono_falso"><b>Telefono: </b>Si</p>
                    <ng-template #telefono_falso><p class="col-md-12"><b>Telefono: </b>No</p></ng-template>
                    <p class="col-md-12" *ngIf="property.cable == 'true'; else cable_falso"><b>Cable: </b>Si</p>
                    <ng-template #cable_falso><p class="col-md-12"><b>Cable: </b>No</p></ng-template>
                </div>
            </div>
            
           
        </div>
        <div id="galery" class="col-lg-5 col-md-12 px-4 py-5">
            <div class="col-md-12 my-3">
                <div class="row d-flex justify-content-around">
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #e80913;">Tipo de operacion</b>
                        <b class="col-md-12 p-0" *ngIf="property.property_in != ''; else property_in_null" style="font-size: 1.3rem;">{{property.property_in}}</b>
                        <ng-template #property_in_null><p class="col-md-12"><b>-</b></p></ng-template>
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #e80913;">Antiguedad</b>
                        <b class="col-md-12 p-0" *ngIf="property.antiquity != ''; else property_in_null" style="font-size: 1.3rem;">{{property.antiquity}} años</b>
                        <ng-template #antiquity_null><p class="col-md-12"><b>-</b></p></ng-template>
                    </div>
                    <div class="col-md-4 row text-center">
                        <b class="col-md-12 p-0" style="color: #e80913;">Superficie cubierta</b>
                        <b class="col-md-12 p-0" *ngIf="property.surface != ''; else property_in_null" style="font-size: 1.3rem;">{{property.surface}} m²</b>
                        <ng-template #surface_null><p class="col-md-12"><b>-</b></p></ng-template>
                    </div>
                </div>
            </div>
            <div id="contact" class="row d-flex justify-content-center">
                
                <div class="col-md-12 row ">
                    <img (click)="showCarousel()" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F{{img}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518"  class="img-principal col-md-12 img-fluid">
                    <div class="col-md-12 my-4" >
                        <button *ngFor="let i of [0,1,2,3,4,5,6]" class="img-{{i}} col-md-3" (click)="changeImg('img-'+i, i)"> <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F{{i}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" onerror="this.style.display='none'" class="img-fluid" ></button>
                        <button  class="img-7 col-3 p-1" (click)="changeImg('img-7', 7)" disabled> <img src="../../assets/images/42cc1e87-e3a3-4e9c-a7ae-c3a21ca6c58e.jpg" class="img-fluid" ></button>
                    </div>                    
                </div>               
            </div>    
            
        </div>
        <div class="col-md-12 row">
            <p style="color: #e80913;"><b>Ubicacion</b></p>
             <!--Google map-->
             <div id="map-container-google-3" class="col-md-8 z-depth-1-half map-container-3 p-2" style="height: 300px;">
                <iframe id="map" 
                src="#" frameborder="0"
                  style="border:0;" allowfullscreen></iframe>
              </div>
            <!--Google Maps--> 

            <div id="" class="col-md-4 col-12 px-md-5">
                <p style="color: #e80913; font-size: 1.5rem;"><b>Su consulta no molesta</b></p>
                <div class="col-md-12 row">
                    <p class="col-12">Consultar con Alberto</p>
                    <a class="col-md-12 col-12 aling-items-center py-1 px-md-4" href="https://api.whatsapp.com/send?text={{url}}/propiedad?id={{property.key}}&phone=+5492215437743">
                        <div id="icon-wspp" class="row">
                            <p class="col-md-6 col-6">Via whatsapp</p>
                            <img class="col-6" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" >
                        </div>
                    </a>
                    <a class="col-md-12 col-12 aling-items-center py-1 px-md-4" href="mailto:amdq20@gmail.com?Subject=Interesado%20en%20la%20Propiedad&body={{url}}/propiedad?id={{property.key}}">
                        <div id="icon-email" class="row">
                            <p class="col-md-6 col-6">Via e-mail</p>
                            <img class="col-6"  src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" >
                        </div>
                    </a>
                </div>
                <div class="col-md-12 row">
                    <p class="col-12">Consultar con Paola</p>
                    <a class="col-md-12 aling-items-center py-1 px-md-4" href="https://api.whatsapp.com/send?text={{url}}/propiedad?id={{property.key}}&phone=+5492215058811">
                        <div id="icon-wspp" class="row">
                            <p class="col-md-6 col-6">Via whatsapp</p>
                            <img class="col-6" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/240px-WhatsApp.svg.png" >
                        </div>
                    </a>
                </div>
                <div class="col-md-12 my-2">
                    <div id="shared" class="row ">
                        <p class="col-md-3">Compartir:</p>
                        <a class="col-md-3 col-4 text-center" href="https://www.facebook.com/sharer/sharer.php?u=comesañapropiedades.com.ar/propiedad?id={{property.key}}&quote=holaaa">Facebook</a>
                        <a class="col-md-3 col-4 text-center" href="https://twitter.com/intent/tweet?text=Comesaña Propiedades:%0A%0A&url={{url}}/propiedad?id={{property.key}}">Twitter</a>
                        <a class="col-md-3 col-4 text-center" href="https://api.whatsapp.com/send?text=Comesaña Propiedades:%0A%0A{{url}}/propiedad?id={{property.key}}">WhatsApp</a>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="my-5 OOO">
            <p style="color: #e80913; font-size: 1.5rem;"><b>Tambien te podria interesar</b></p>

            <div id="similar-property-mbl">
                <div class="px-auto mx-2 xlxl" *ngFor="let property of similar_properties.slice(0,6)">
                    <div  class="col-12" style="width: 250px; height: 150px;">
                        <img style="width: 250px; height: 150px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class=" img-fluid">
                    </div>
                    <div class="col-12" style="width: 250px;">
                        <a href="{{url}}/propiedad?id={{property.key}}"><b>{{property.title | slice:0:20}}...</b></a>
                        <p style="white-space: normal; height: 60px; font-size: 0.7rem;">{{property.title}}</p>
                    </div>
                    <div id="" class="row col-12 icon d-flex justify-content-end" >
                        <img src="../../assets/images/icon-ambiente.png" class="img-fluid">
                        <p class="col-md-3 col-3 px-1">{{property.ambiente}} amb.</p>
                        <img src="../../assets/images/icon-room.png" class="img-fluid">
                        <p class="col-md-3 col-3 px-1">{{property.room}} dorm.</p>
                        <img src="../../assets/images/icon-toilet.png" class="img-fluid">
                        <p class="col-md-3 col-3 px-1">{{property.toilet}} baños.</p>
                    </div>
                </div>
            </div>

            <div class="row similar-property my-1 d-flex justify-content-around flex-wrap p-0">
                <div class="row col-md-2 col-12 my-2 property-img" *ngFor="let property of similar_properties.slice(0,6)">
                    <div class="col-12">
                        <img src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{property.key}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class=" img-fluid">   
                        <a class="col-md-12 col-12 title" href="{{url}}/propiedad?id={{property.key}}"><b>{{property.title | slice:0:20}}...</b></a>
                        <p class="col-md-12 col-12 my-1" style="height: 90px;">
                            {{property.description.toLowerCase() | slice:0:60 }}...
                        </p>
                        <div class="row col-md-12 col-12 icon d-flex justify-content-between">
                            <div class="col-md-4 px-1 row">
                                <img src="../../assets/images/icon-ambiente.png" class="img-fluid">
                                <p class="col-4">{{property.ambiente}}amb.</p>
                            </div>
                            <div class="col-md-4 px-1 row">
                                <img src="../../assets/images/icon-room.png" class="img-fluid">
                                <p class="col-4">{{property.room}}dorm.</p>
                            </div>
                            <div class="col-md-4 px-1 row">
                                <img src="../../assets/images/icon-toilet.png" class="img-fluid">
                                <p class="col-4">{{property.toilet}}baños.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="carousel" class="col-md-12 col-12">
    <div class="col-md-12 d-flex justify-content-end">
        <button (click)="closeCarousel()" class="mx-4" style="background-color: transparent; color: white">X</button>
    </div>
    <div class="col-md-12 col-12 d-flex justify-content-center">
        <div id="demo" class=" carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img id="img-carrousel-principal" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" alt="Los Angeles">
                    <!-- Left and right controls -->
                <button id="prev" class="carousel-control-prev"  data-slide="prev" (click)="prevImg()">
                    <span class="carousel-control-prev-icon"></span>
                </button>
                <button id="next" class="carousel-control-next"  data-slide="next" (click)="nextImg()">
                    <span class="carousel-control-next-icon"></span>
                </button>  
                </div>    
                
            </div> 
            <div id="all-images" class="my-3">
                <div class="col-md-12 row" *ngFor="let img of cant_img_carrousel">
                    <div style="width: 100px;">
                        <img style="width: 150px; height: 150px;" src="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F{{img}}?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" class=" img-fluid">
                    </div>
                </div>
            </div>    
            
        </div>
    </div>
    
</div>

<meta property="og:title" content="{{property.title}}" />
<meta property="og:description" content="{{property.description.toLowerCase() | slice:0:60 }}..." />
<meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/comesaniapropiedades.appspot.com/o/{{id}}%2F0?alt=media&token=a4f7f54f-74bf-4496-841e-8a4a36537518" />      
